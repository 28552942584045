<template>
	<div>
		<HeaderPage/>
	<main>
	<section class="breadcrumbs">
      <div class="container">

        <ol>
          <li><a href="/">Home</a></li>
          <li>About</li>
        </ol>

      </div>
    </section>

    <!-- ======= Blog Section ======= -->
    <section id="blog" class="blog">
      <div class="container" data-aos="fade-up">
        <div class="row">
            <div class="entry-title">
                <h2>Dreams Became Reality</h2><br/>
            </div>
            <div class="col-8 entries">
            <article class="entry">
                <div class="entry-content">
                    <p>Established on the recognition of the four needs of a human being - to live, to love, to learn and to leave a legacy- emerged Vilsons; founded by Late Shri J Ponnusamy Villavarayar, a visionary, a dominant force in the Gulf of Mannar, a legend of his time and a firm believer that enduring success never comes easily to an Individual or to an Organization.</p>
                  <p>Our struggle to put first things first can be characterized by the contrast between two powerful tools that direct us: the Clock and the Compass.</p>
                  <p>The Clock represents our commitments, appointments, schedules, goals, activities - what we do with, and how we manage our time. The Compass represents our vision, values, principles, mission, conscience, direction - what we feel is deeply important and how we lead our lives.</p>
                  <p>Between Stimulus and Response, there is a space. In t/oat Space is Our Power to Choose Our Response, In Our Response lies Our Growth and Freedom.</p>
                </div>
            </article>
            </div>
            <div class="col-4">
                <div class="entry-img">
                    <img src="assets/img/dreams1.jpeg" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
    </section>

  </main>
	</div>
</template>


<script>
import HeaderPage from '@/components/Header.vue';
export default {
  name: 'Aboutpage',
  components: {
    HeaderPage,
  },
}
</script>